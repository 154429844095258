import { useState } from 'react';
import { FormRow } from '../../../styles/common';
import { Autocomplete, Button, Input } from '../../../components';
import trashIcon from '../../../assets/icons/trash-icon.svg';
import addIcon from '../../../assets/icons/add.svg';
import * as Style from '../duplicate-transit-row/duplicate-transit-row.style';

interface IChildInput {
  type: 'Autocomplete' | 'Input';
  name: (lineIdx: number, sealIdx?: number) => string;
  search?: (searchText: string) => Promise<{ value: string; label: string }[]>;
  label: string;
}

interface InputConfig {
  type: 'Autocomplete' | 'Input';
  name: (lineIdx: number) => string;
  search?: (searchText: string) => Promise<{ value: string; label: string }[]>;
  label: string;
  childs?: IChildInput[];
}

interface TransitDetailsTransportEquipmentProps {
  form: any;
  inputs: InputConfig[];
}

export const TransitDetailsTransportEquipment: React.FC<TransitDetailsTransportEquipmentProps> = ({ form, inputs }) => {
  const [rows, setRows] = useState<number[]>([0]);
  const [sealCounts, setSealCounts] = useState<{ [key: number]: number[] }>({ 0: [0] });

  const addRow = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newRowIdx = rows.length;
    setRows([...rows, newRowIdx]);
    setSealCounts({ ...sealCounts, [newRowIdx]: [0] });
  };

  const removeRow = (rowIdx: number) => {
    setRows(rows.filter((_, index) => index !== rowIdx));
    setSealCounts((prev) => {
      const updatedSeals = { ...prev };
      delete updatedSeals[rowIdx];
      return updatedSeals;
    });

    inputs.forEach((inputConfig) => {
      form.setFieldValue(inputConfig.name(rowIdx), undefined); // Clear field value for this row
      inputConfig.childs?.forEach((child, sealIdx) => form.setFieldValue(child.name(rowIdx, sealIdx), undefined));
    });
  };

  const addSeal = (rowIdx: number) => {
    setSealCounts((prev) => ({
      ...prev,
      [rowIdx]: [...(prev[rowIdx] || []), prev[rowIdx]?.length || 0],
    }));
  };

  const removeSeal = (rowIdx: number, sealIdx: number) => {
    setSealCounts((prev) => ({
      ...prev,
      [rowIdx]: prev[rowIdx].filter((_, index) => index !== sealIdx),
    }));
    inputs[0].childs?.forEach((child) => {
      form.setFieldValue(child.name(rowIdx, sealIdx), undefined);
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {rows.map((rowIdx) => (
        <div key={rowIdx} style={{ display: 'flex', marginBottom: '15px' }}>
          <FormRow>
            <div>
              {inputs.map((inputConfig, index) => (
                <div key={index}>
                  {inputConfig.type === 'Autocomplete' ? (
                    <Autocomplete
                      placeholder={`${inputConfig.label} ${rowIdx + 1}`}
                      fetchOptions={inputConfig.search}
                      onChange={(selectedOption) => form.setFieldValue(inputConfig.name(rowIdx), selectedOption.value)}
                      value={{
                        value: form.values[inputConfig.name(rowIdx)] || '',
                        label: form.values[inputConfig.name(rowIdx)] || '',
                      }}
                      width={100}
                      widthUnit="%"
                    />
                  ) : (
                    <FormRow>
                      <Input
                        placeholder={`${inputConfig.label} ${rowIdx + 1}`}
                        onChange={(evt) => form.setFieldValue(inputConfig.name(rowIdx), evt.target.value)}
                        name={inputConfig.name(rowIdx)}
                        width={100}
                        widthUnit="%"
                        defaultBehaviour
                      />
                    </FormRow>
                  )}

                  {inputConfig.childs && (
                    <>
                      {sealCounts[rowIdx]?.map((sealIdx) => (
                        <div key={sealIdx} style={{ marginTop: '10px', display: 'flex' }}>
                          {inputConfig.childs?.map((child) => (
                            <div key={child.label} style={{ marginRight: '10px' }}>
                              {child.type === 'Autocomplete' ? (
                                <Autocomplete
                                  placeholder={`${child.label} ${sealIdx + 1}`}
                                  fetchOptions={child.search}
                                  onChange={(selectedOption) =>
                                    form.setFieldValue(child.name(rowIdx, sealIdx), selectedOption.value)
                                  }
                                  value={{
                                    value: form.values[child.name(rowIdx, sealIdx)] || '',
                                    label: form.values[child.name(rowIdx, sealIdx)] || '',
                                  }}
                                  width={100}
                                  widthUnit="%"
                                />
                              ) : (
                                <Input
                                  placeholder={`${child.label} ${sealIdx + 1}`}
                                  onChange={(evt) => form.setFieldValue(child.name(rowIdx, sealIdx), evt.target.value)}
                                  name={child.name(rowIdx, sealIdx)}
                                  width={100}
                                  widthUnit="%"
                                />
                              )}
                            </div>
                          ))}
                          <button
                            style={{ cursor: 'pointer' }}
                            onClick={(event) => {
                              event.preventDefault();
                              addSeal(rowIdx);
                            }}
                          >
                            <img src={addIcon} alt="Add Seal" />
                          </button>
                          <Style.DeleteButton onClick={() => removeSeal(rowIdx, sealIdx)}>
                            <img
                              src={trashIcon}
                              alt="Remove Seal"
                              style={{
                                cursor: 'pointer',
                                marginTop: '5px',
                              }}
                            />
                          </Style.DeleteButton>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              ))}
            </div>
          </FormRow>
          <FormRow>
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', marginTop: '5px' }}>
                <Style.Item>
                  <Button outline onClick={addRow}>
                    Add Container
                  </Button>
                </Style.Item>
                <Style.DeleteButton
                  onClick={() => removeRow(rowIdx)}
                  style={{ cursor: 'pointer', marginBottom: '12px' }}
                >
                  <img src={trashIcon} alt="Remove Row" />
                </Style.DeleteButton>
              </div>
            </div>
          </FormRow>
        </div>
      ))}
    </div>
  );
};

export default TransitDetailsTransportEquipment;
