/* eslint-disable max-classes-per-file */
import { prop } from '@typegoose/typegoose';

import { CompanyAddress } from './company-address';
import { Evaluation } from './evaluation';
import { RelatedResource } from './related-resource';

export class Eori {
  @prop()
  number: string;

  @prop()
  address?: CompanyAddress;

  @prop({ type: () => [RelatedResource] })
  relatedResources?: RelatedResource[];

  @prop()
  evalEORI?: Evaluation;
}

export class VAT {
  @prop()
  number: string;

  @prop()
  address?: CompanyAddress;

  @prop({ type: () => [RelatedResource] })
  relatedResources?: RelatedResource[];

  @prop()
  evalVAT?: Evaluation;
}

export class TaxReturn {
  @prop({ type: () => [RelatedResource] })
  relatedResources?: RelatedResource[];

  @prop()
  evalTaxReturn?: Evaluation;
}

export class ImportRecords {
  @prop({ type: () => [RelatedResource] })
  relatedResources?: RelatedResource[];

  @prop()
  evalImportRecords?: Evaluation;
}

export class CustomsRepresentative {
  @prop()
  companyRegistrationNumber: string;

  @prop()
  vatNumber: string;

  @prop()
  customsRegistrationNumber: string;

  @prop()
  address?: CompanyAddress;
}

export class VatRepresentative {
  @prop()
  vatNumber: string;

  @prop()
  address?: CompanyAddress;
}

export class Poa {
  @prop()
  startingDate: Date;

  @prop()
  expirationDate: Date;

  @prop()
  customsRepresentative: CustomsRepresentative;

  @prop({ type: () => [RelatedResource] })
  relatedResources?: RelatedResource[];
}

export class VatGuarantee {
  @prop()
  startingDate: Date;

  @prop()
  expirationDate: Date;

  @prop()
  vatRepresentative: VatRepresentative;

  @prop({ type: () => [RelatedResource] })
  relatedResources?: RelatedResource[];
}

export class BusinessLicense {
  @prop()
  nameEN: string;

  @prop()
  nameCN: string;

  @prop()
  registrationNumber: string;

  @prop()
  bankAccount: string;

  @prop()
  address?: CompanyAddress;

  @prop({ type: () => [RelatedResource] })
  relatedResources?: RelatedResource[];

  @prop()
  evalRegistration?: Evaluation;
}
