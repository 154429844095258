import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
  `}
`;

export const SvgIcon = styled.div`
  ${tw`
    flex
    items-center
  `}
  width: 20px;
  height: 20px;
  margin-left: -10px;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: #939393;
  }
`;
