/* eslint-disable complexity */
import { FormikProps } from 'formik';

import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../../../../../../styles/common';
import { Input } from '../../../../../../../../components';
import TransitDuplicateRow from '../../../../../../duplicate-transit-row/duplicate-transit-row.component';
import { HouseItemFormInitializerReturn } from '../house-form-initializer';

interface HouseStakeholdersProps {
  form: FormikProps<HouseItemFormInitializerReturn>;
  disabled: boolean;
}

export const HouseStakeholders: React.FC<HouseStakeholdersProps> = (props) => {
  const { form } = props;

  return (
    <>
      <FormContainer>
        <FormSection verticalPadding={0} noTopPadding>
          <FormSectionTitle noTopBorder>Consignor</FormSectionTitle>
          <FormRow>
            <Input
              name="Consignor.identificationNumber"
              placeholder="Identification number"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignor.identificationNumber}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            <Input
              name="Consignor.name"
              placeholder="Name"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignor.name}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
          </FormRow>
          <FormRow>
            <Input
              name="Consignor.Address.streetAndNumber"
              placeholder="Street and Number"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignor.Address.streetAndNumber}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            <Input
              name="Consignor.Address.postcode"
              placeholder="Postcode"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignor.Address.postcode}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            <Input
              name="Consignor.Address.city"
              placeholder="City"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignor.Address.city}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            <Input
              name="Consignor.Address.country"
              placeholder="Country"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignor.Address.country}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
          </FormRow>
        </FormSection>
        <FormSection verticalPadding={0} noTopPadding>
          <FormSectionTitle noTopBorder>Consignee</FormSectionTitle>
          <FormRow>
            <Input
              name="Consignee.identificationNumber"
              placeholder="Identification number"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignee.identificationNumber}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            <Input
              name="Consignee.name"
              placeholder="Name"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignee.name}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
          </FormRow>
          <FormRow>
            <Input
              name="Consignee.Address.streetAndNumber"
              placeholder="Street and Number"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignee.Address.streetAndNumber}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            <Input
              name="Consignee.Address.postcode"
              placeholder="Postcode"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignee.Address.postcode}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            <Input
              name="Consignee.Address.city"
              placeholder="City"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignee.Address.city}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            <Input
              name="Consignee.Address.country"
              placeholder="Country"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignee.Address.country}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
          </FormRow>
        </FormSection>
        <FormSection verticalPadding={0}>
          <FormSectionTitle>Additional supply chain actor</FormSectionTitle>
          <TransitDuplicateRow
            addButtonLabel="Add new additional supply chain actor"
            form={form}
            inputs={[
              {
                type: 'Input',
                label: 'Role',
                name: 'role',
              },
              {
                type: 'Input',
                label: 'Identification number',
                name: 'identificationNumber',
              },
            ]}
            arrayPath="AdditionalSupplyChainActor"
            compact
          />
        </FormSection>
      </FormContainer>
    </>
  );
};
