/* eslint-disable max-classes-per-file, max-lines */
import { prop, Ref } from '@typegoose/typegoose';

import { DeclarationDocumentsAdditionalFiscalReferenceRole } from '../enums';
import { BaseModel } from './base.model';
import { BusinessCardProof } from './customs-domain/business-card-proof';
import { DomainCollection } from './domain.collection';
import { GoodLocation } from './goodLocation';
import { User } from './user';

export enum ExternalSystem {
  descartes = 'descartes',
  xcomm = 'xcomm',
  begate = 'begate',
  'disabled-xcomm' = 'disabled-xcomm',
}

export class DeclarantAddress {
  @prop()
  street: string;

  @prop()
  postcode: string;

  @prop()
  city: string;

  @prop()
  country: string;

  @prop()
  region?: string;
}

export class DeclarantContact {
  @prop()
  name: string;

  @prop()
  phoneNumber: string;

  @prop()
  email: string;
}

export class DeclarantNlSequence {
  @prop()
  year: number;

  @prop()
  sequence: number;
}

export class DeclarantRepresentative {
  @prop()
  identificationNumber: string;

  @prop()
  name: string;

  @prop()
  status: number;

  @prop({ _id: false, type: DeclarantAddress })
  address: DeclarantAddress;

  @prop({ type: DeclarantContact })
  contactPerson: DeclarantContact;

  @prop()
  registration: string;
}

export class DeclarantCustomsAuth {
  @prop({ required: true })
  clientId: string;

  @prop({ required: true })
  password: string;

  @prop({ required: true })
  systemName: string;

  @prop({ required: true })
  enterpriseNumber: string;

  @prop()
  secret: string;

  @prop({ default: 3 })
  sendingDelaySeconds: number;
}

export class DeclarantPaymentsFiscalReference {
  @prop({ enum: DeclarationDocumentsAdditionalFiscalReferenceRole })
  role: string;

  @prop()
  vatIdentificationNumber: string;
}

export class DeclarantPayments {
  @prop()
  methodOfPayment: string;

  @prop()
  deferredPayment: string;

  @prop()
  methodOfPaymentVAT: string;

  @prop()
  deferredPaymentVAT: string;

  @prop({ type: [DeclarantPaymentsFiscalReference] })
  additionalFiscalReference: DeclarantPaymentsFiscalReference[];
}

export class DeclarantRiskCalculation {
  @prop()
  riskAnalysisEnabled: boolean;

  @prop()
  amazonFeesCalculationEnabled: boolean;

  @prop()
  screenshotsEnabled: boolean;

  @prop()
  aiModuleEnabled: boolean;

  @prop()
  penaltyValue: number;

  @prop()
  penaltyDescription: number;

  @prop()
  penaltyHSCode: number;

  @prop()
  requestProofEnabled: boolean;

  @prop()
  vatValidationEnabled: boolean;

  @prop()
  eoriValidationEnabled: boolean;
}

export class DeclarantValidationSettings {
  @prop({ default: true })
  h7ExcludedCodesValidationEnabled: boolean;

  @prop({ default: false })
  IOSSExcludedValidationEnabled: boolean;

  @prop({ type: [String], default: [] })
  IOSSBlackList: string[];

  @prop({ default: false })
  VatEoriExcludedValidationEnabled: boolean;

  @prop({ type: [String], default: [] })
  VatEoriBlackList: string[];

  @prop({ default: false })
  SameUCRValidationEnabled: boolean;

  @prop({ default: false })
  YListEnabled: boolean;

  @prop({ type: [String], default: [] })
  YList: string[];
}

export class ExternalSystemCustoms {
  @prop({ enum: ExternalSystem })
  systemName: ExternalSystem;

  @prop()
  systemProtocol: string;

  @prop()
  hostname: string;

  @prop()
  port: number;

  @prop()
  username: string;

  @prop()
  password: string;

  @prop()
  incomingPath: string;

  @prop()
  outgoingPath: string;

  @prop()
  incomingPathBegateFreed: string;

  @prop()
  incomingPathBegateBlocked: string;

  @prop()
  incomingPathBegateError: string;

  @prop()
  outgoingPathBeGate: string;
}

export class InvoiceCounter {
  @prop()
  year: number;

  @prop()
  counter: number;
}

class DeclarationCounter {
  @prop()
  year: number;

  @prop()
  counter: number;
}

class DeclarantVatReport extends DeclarantRepresentative {
  @prop()
  excludeProcedure40: boolean;

  @prop()
  ownerName: string;
}

export class LinkedSeller {
  @prop()
  externalId: string;

  @prop()
  name: string;

  @prop()
  walletEndpoint: string;

  @prop()
  proof?: BusinessCardProof;
}

@DomainCollection({ schemaOptions: { timestamps: true, strict: false } })
export class Declarant extends BaseModel {
  @prop({ required: true })
  accountName: string;

  @prop()
  name: string;

  @prop()
  language: string;

  @prop({ type: ExternalSystemCustoms })
  externalSystemCustoms?: ExternalSystemCustoms;

  hasExternalSystemCustoms: boolean; // todo: use a setter (with respect to Declarant.externalSystemCustoms)

  @prop()
  walletEndpoint: string;

  hasWallet: boolean;

  @prop()
  hasInvoiceTemplate: boolean;

  @prop({ type: [InvoiceCounter], _id: false })
  invoiceCounter: InvoiceCounter[];

  @prop({ type: [DeclarationCounter], _id: false })
  declarationCounter: DeclarationCounter[];

  @prop()
  identificationNumber: string;

  @prop({ type: DeclarantAddress })
  address: DeclarantAddress;

  @prop()
  authorisedIdentity: string;

  @prop({ type: DeclarantContact })
  contactPerson: DeclarantContact;

  @prop({ type: DeclarantRepresentative })
  representative: DeclarantRepresentative;

  @prop({ type: DeclarantCustomsAuth })
  customsAuthentication: DeclarantCustomsAuth;

  @prop({ type: DeclarantPayments })
  payments: DeclarantPayments;

  @prop({ type: DeclarantRiskCalculation })
  risksCalculation: DeclarantRiskCalculation;

  @prop({ ref: () => 'GoodLocation' })
  goodsLocations: Ref<GoodLocation>[];

  @prop({ type: DeclarantValidationSettings })
  validationSettings: DeclarantValidationSettings;

  @prop({ ref: () => 'User' })
  users: Ref<User>[];

  @prop({ default: false })
  deleted: boolean;

  @prop({ type: DeclarantVatReport })
  vatReport: DeclarantVatReport;

  @prop({ type: DeclarantNlSequence })
  nlSequence: DeclarantNlSequence[];

  @prop({ type: [LinkedSeller] })
  linkedSellers: LinkedSeller[];
}
