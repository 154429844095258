/* eslint-disable default-case */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
/* eslint-disable complexity */
import { useHistory } from 'react-router-dom';
import { Transit, TransitStatus } from '@e-origin/shared';
import { FormikProps } from 'formik';
import { selectTransit } from '../../../../src/stores/transitsSlice';
import backIcon from '../../../assets/icons/back-icon.svg';
import { Autocomplete, Input } from '../../../components';
import * as Style from './transit-details-header.style';
import { retrieveCodes } from '../../../../src/stores/settingsSlice';
import { selectDeclarant } from '../../../stores/declarantSlice';

interface TransitDetailsProps {
  transitDetails: Transit;
  editDisabled: boolean;
  form: FormikProps<Transit>;
}

const TransitDetailsHeader: React.FC<TransitDetailsProps> = ({ transitDetails, form }) => {
  const declarant = useSelector(selectDeclarant);
  const history = useHistory();
  const transitInfo = useSelector(selectTransit);
  const extractStatus = (status: TransitStatus | undefined): string => {
    switch (status) {
      case TransitStatus.NOT_SENT:
        return 'Not sent';
      case TransitStatus.SENDING:
        return 'Sending';
      case TransitStatus.INVALIDATION_PENDING:
        return 'IN_CONTROL';
      case TransitStatus.INVALIDATED:
        return 'INVALIDATED';
      case TransitStatus.GUARANTEE_NOT_VALID:
        return 'GUARANTEE_NOT_VALID';
      case TransitStatus.CORR_REFUSED_WAITING:
        return 'CORR_REFUSED_WAITING ';
      case TransitStatus.CORR_OK_WAITING:
        return 'CORR_OK_WAITING ';
      case TransitStatus.SENT:
        return 'Sent';
      case TransitStatus.REJECTED:
        return 'Rejected';
      case TransitStatus.ACCEPTED:
        return 'Accepted';
      case TransitStatus.IN_CONTROL:
        return 'In control';
      case TransitStatus.NOT_RELEASED:
        return 'Not released';
      case TransitStatus.RELEASED:
        return 'Released for transit';
      case TransitStatus.FINISHED:
        return 'Transit finished';
      default:
        return 'Unknown status';
    }
  };

  const status = useMemo(() => extractStatus(transitInfo?.generalInfo?.status), [transitInfo?.generalInfo?.status]);

  return (
    <Style.Header>
      <Style.BackButton
        onClick={() => {
          history.goBack();
        }}
      >
        <img src={backIcon} alt="" />
        Back
      </Style.BackButton>
      <Style.Summary>
        <div>
          <label>Transit name</label>
          <p>{transitDetails.name}</p>
        </div>
        <div>
          <label>Status</label>
          <p className="status">{status}</p>
        </div>
      </Style.Summary>
      <Style.CommonInputs>
        <Style.CommonInputsRow>
          <Input
            width={220}
            widthUnit="px"
            placeholder="Declaration type"
            value={transitDetails?.TransitOperation.declarationType}
            disabled
          />
          <Input style={{ width: '240px' }} placeholder="LRN" value={transitDetails?.TransitOperation.LRN} disabled />
          <Input
            width={150}
            widthUnit="px"
            placeholder="Security"
            value={transitDetails?.TransitOperation.security}
            disabled
          />
          <Input width={240} widthUnit="px" placeholder="MRN" value={transitDetails?.TransitOperation.MRN} disabled />
          {/* todo - addapt to work with array as per domain model */}
          {/* <Input
            placeholder="Amount to be covered"
            value={transitDetails.Guarantee.GuaranteeReference.amountToBeCovered}
            disabled
          /> */}
          <Input
            width={200}
            widthUnit="px"
            placeholder="Number of Packages"
            value={transitDetails?.Consignment.totalNumberOfPackages}
            disabled
          />
        </Style.CommonInputsRow>

        <Style.CommonInputsRow>
          <Input
            width={150}
            widthUnit="px"
            placeholder="Gross Mass"
            value={transitDetails?.Consignment.grossMass || ''}
            disabled
          />
          <Input
            width={200}
            widthUnit="px"
            placeholder="Seal reference number"
            value={transitDetails?.Consignment.totalNumberOfSeals || ''}
            disabled
          />
          <Input
            placeholder="Reduced Data set Indicator"
            width={200}
            widthUnit="px"
            value={transitDetails?.TransitOperation.reducedDatasetIndicator}
            disabled
          />
          <Input
            width={270}
            widthUnit="px"
            placeholder="CustomsOfficeOfDestinationDeclared"
            value={transitDetails?.CustomsOfficeOfDestinationDeclared.referenceNumber || ''}
            disabled
          />

          <Autocomplete
            width={230}
            widthUnit="px"
            placeholder="Simplified"
            fetchOptions={(search: string) => retrieveCodes('CL027', search, declarant.language)}
            onChange={(selectedOption) =>
              form.setFieldValue('TransitOperation.simplifiedProcedure', selectedOption?.value)
            }
            value={{
              value: form.values?.TransitOperation?.simplifiedProcedure,
              label: form.values?.TransitOperation?.simplifiedProcedure,
            }}
            disabled
          />
        </Style.CommonInputsRow>
      </Style.CommonInputs>
    </Style.Header>
  );
};

export default TransitDetailsHeader;
