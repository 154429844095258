// eslint-disable-next-line max-classes-per-file
import { prop } from '@typegoose/typegoose';

export enum RelatedResourceStatus {
  NEW = 'NEW',
  EMPTY = 'EMPTY',
  NOT_SENT = 'NOT_SENT',
  IN_PROGRESS = 'IN_PROGRESS',
  SENT = 'SENT',
  NOK = 'NOK',
  OK = 'OK',
}

export enum RelatedResourceIssuerType {
  DECLARANT = 'DECLARANT',
  SELLER = 'SELLER',
}

export class RelatedResourceError {
  @prop()
  reason: string;
}

export class RelatedResource {
  @prop()
  fileName?: string;

  @prop()
  mediaType?: string;

  @prop()
  digestMultibase?: string;

  @prop({ enum: RelatedResourceStatus })
  status?: RelatedResourceStatus;

  @prop({ type: [String] })
  // eslint-disable-next-line camelcase
  status_log?: string[];

  @prop({ enum: RelatedResourceIssuerType })
  issuedBy?: RelatedResourceIssuerType;

  @prop()
  signedUrl?: string;

  @prop()
  issuedAt?: Date;

  @prop({ type: () => [RelatedResourceError] })
  errorsStack?: RelatedResourceError[];
}
