/* eslint-disable max-statements */

import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomTable } from '../../../components';
import {
  fetchTransits,
  nextTransits,
  selectTransitFilters,
  selectTransitPagination,
  selectTransits,
  selectTransitSorting,
  selectTransitTotaItems,
  setTransitSorting,
} from '../../../stores/transitsSlice';
import { useTransitsColumns } from './transits-table-column';

export const TransitsTable: React.FC = () => {
  const dispatch = useDispatch();
  const transitList = useSelector(selectTransits);
  const totalTransits = useSelector(selectTransitTotaItems);

  const filters = useSelector(selectTransitFilters);
  const sorting = useSelector(selectTransitSorting);
  const pagination = useSelector(selectTransitPagination);

  const criteriaInitialized = useRef<boolean>(false);
  const firstQuery = useRef<boolean>(true);

  useEffect(() => {
    if (!criteriaInitialized.current) {
      return;
    }
    dispatch(fetchTransits({ persistPagination: firstQuery.current }));
    firstQuery.current = false;
  }, [sorting, pagination.size, filters]);

  useEffect(() => {
    dispatch(fetchTransits());
    criteriaInitialized.current = true;
  }, []);

  const columns = useTransitsColumns();

  const handleSorting = (updatedSorting) => {
    dispatch(setTransitSorting(updatedSorting));
  };

  const handlePagination = (updatedPagination) => {
    dispatch(nextTransits(updatedPagination));
  };

  return (
    <>
      <CustomTable
        columns={columns.current}
        pagination={pagination}
        sorting={sorting}
        onSortingChange={handleSorting}
        onPaginationChange={handlePagination}
        data={transitList || []}
        total={totalTransits}
        isLoading={false}
        disablePagination={false}
      />
    </>
  );
};
