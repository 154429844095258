/* eslint-disable max-statements */
/* eslint-disable complexity */
import { EORIGIN_DATE_FORMAT, TransitDocumentsEnum } from '@e-origin/shared';
import { get } from 'lodash';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { FormRow } from '../../../styles/common';

import {
  TransitDocumentFormSchema,
  TransitDocumentFormSchemaProps,
  FormDocumentsInputType,
} from './transit-document-form-schema';

import { useSelector } from 'react-redux';
import { selectDeclarant } from '../../../stores/declarantSlice';
import { Autocomplete, DatePicker, Input } from '../../../components';

interface DocumentFormDataProps {
  id: {
    [key: string]: any;
  };
  data: { [key: string]: any };
}

interface DeclarationDocumentsFormFactoryProps {
  type: TransitDocumentsEnum;
  editDisabled: boolean;
  onChange: (newData: DocumentFormDataProps) => void;
  data?: any;
}

export const TransitDocumentFormFactory: React.FC<DeclarationDocumentsFormFactoryProps> = (props) => {
  if (!props.type) {
    return null;
  }

  const declarant = useSelector(selectDeclarant);

  const [formSchema, setFormSchema] = useState<TransitDocumentFormSchemaProps['any']>(undefined);
  const [formData, setFormData] = useState<DocumentFormDataProps>();

  const buildFormData = () =>
    (Object.keys(formSchema.fields || {}) || []).reduce<DocumentFormDataProps>((acc, fieldPath) => {
      if (TransitDocumentFormSchema[props.type].fields[fieldPath]?.type === FormDocumentsInputType.NUMBER) {
        return {
          ...acc,
          data: { ...acc.data, [fieldPath]: Number(get(props.data, fieldPath)) || undefined },
        };
      }

      if (TransitDocumentFormSchema[props.type].fields[fieldPath]?.type === FormDocumentsInputType.DATE) {
        const dateValue = get(props.data, fieldPath);
        return {
          ...acc,
          data: {
            ...acc.data,
            [fieldPath]: dateValue ? moment(dateValue, EORIGIN_DATE_FORMAT).format(EORIGIN_DATE_FORMAT) : undefined,
          },
        };
      }

      return {
        ...acc,
        data: {
          ...acc.data,
          [fieldPath]: get(props.data, fieldPath),
        },
      };
    }, {} as any);

  useEffect(() => {
    setFormData(undefined); // reset the form before reconstruct it
    setFormSchema(TransitDocumentFormSchema[props.type] || ({} as any));
  }, [props.type]);

  useEffect(() => {
    if (formSchema) {
      const data = buildFormData();
      setFormData(data);

      props.onChange({
        ...data,
        // data: Object.keys(data.data).reduce(
        //   (acc, curPath) => merge(acc, convertDotNotationToObject(curPath, data.data[curPath])),
        //   {},
        // ),
      });
    }
  }, [formSchema]);

  const setFieldValue = (path: string, value: any) => {
    const newFormData = {
      ...formData,
      data: {
        ...formData.data,
        [path]: value,
      },
    };

    setFormData(newFormData);

    props.onChange({
      ...newFormData,
      // data: Object.keys(newFormData.data).reduce(
      //   (acc, curPath) => merge(acc, convertDotNotationToObject(curPath, newFormData.data[curPath])),
      //   {},
      // ),
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.name, e.target.type === 'number' ? Number(e.target.value) : e.target.value);
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.name, e.target.type === 'number' ? Number(e.target.value) : e.target.value);
  };

  return (
    <>
      {formData &&
        Object.keys(formSchema.fields).map((path, idx) => {
          const { type, options, placeholder } = formSchema.fields[path];

          if (type === FormDocumentsInputType.AUTOCOMPLETE) {
            return (
              <FormRow key={idx}>
                <Autocomplete
                  width={100}
                  widthUnit="%"
                  fetchOptions={(search) => formSchema.fields[path].search(search, declarant.language)}
                  placeholder={placeholder}
                  options={
                    !formSchema.fields[path].search
                      ? options?.map((code) =>
                          typeof code === 'object' ? code : { value: code.toString(), label: code.toString() },
                        )
                      : undefined
                  }
                  onChange={(selectedOption) => {
                    setFieldValue(path, selectedOption?.value);
                  }}
                  value={{
                    value: formData.data?.[path] || '',
                    label: formData.data?.[path] || '',
                  }}
                  disabled={props.editDisabled}
                />
                {/* {formData.data?.[path]?.label && <Tooltip content={formData.data?.[path]?.label} />} */}
              </FormRow>
            );
          }

          if ([FormDocumentsInputType.INPUT, FormDocumentsInputType.NUMBER].includes(type)) {
            return (
              <FormRow key={idx}>
                <Input
                  key={idx}
                  name={path}
                  placeholder={placeholder}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={formData.data?.[path]}
                  width={100}
                  widthUnit="%"
                  disabled={props.editDisabled}
                  type={type === FormDocumentsInputType.NUMBER ? 'number' : 'text'}
                />
              </FormRow>
            );
          }

          if (type === FormDocumentsInputType.DATE) {
            return (
              <FormRow key={idx}>
                <DatePicker
                  name={path}
                  placeholder={placeholder}
                  value={formData.data?.[path] && moment(formData.data?.[path], EORIGIN_DATE_FORMAT).toDate()}
                  onChange={(value: Date) =>
                    setFieldValue(
                      path,
                      value ? moment(value, EORIGIN_DATE_FORMAT).format(EORIGIN_DATE_FORMAT) : undefined,
                    )
                  }
                  disabled={props.editDisabled}
                />
              </FormRow>
            );
          }

          return null;
        })}
    </>
  );
};
