import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-col
  `}
  padding-right: 0%;
`;

export const Title = styled.h4`
  font-size: 18px;
  font-weight: 600;
  margin-top: 40px;
`;

export const AuthButton = styled.div`
  margin-top: 16px;
`;
