/* eslint-disable default-case */
/* eslint-disable complexity */
/* eslint-disable max-statements */

import { FormikProps } from 'formik';
import { useState } from 'react';
import DataTable, { IDataTableProps } from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Customer } from '@e-origin/shared';

import { Button, TableActionButtons, TableActions } from '../../../components';
import { EditCustomerType } from '../../../enums';
import { DataTableContainer } from '../../../pages/manage-account/goods-location/goods-location.style';
import { CustomerDetailsParamsType } from '../customer-details.component';
import { authorisationTypes } from './authorisationTypes';
import * as Style from './customer-details-transit-auth.styles';
import { TransitAuthFormModal } from './transit-auth-form-modal/transit-auth-form-modal.component';

interface CustomerDetailsTransitAuthProps {
  handleSubmitCustomer(type: EditCustomerType): void;
  form: FormikProps<Customer>;
}

const CustomerDetailsTransitAuth: React.FC<CustomerDetailsTransitAuthProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedReference, setSelectedReference] = useState<{ type: string; referenceNumber: string }>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(null);
  const { customerId } = useParams<CustomerDetailsParamsType>();
  const context = customerId === 'add' ? 'CREATE' : 'UPDATE';

  const toggleShowReferenceModal = () => {
    setShowModal(!showModal);

    if (!showModal) {
      setSelectedReference(null);
    }
  };

  const handleTableAction = (actionType: string, row: { type: string; referenceNumber: string }, rowIndex: number) => {
    if (actionType === 'view') {
      setSelectedReference(row);
      setSelectedIndex(rowIndex);
      setShowModal(true);
    }

    if (actionType === 'delete') {
      const transitAuthorisations = [...props.form.values.transitAuthorisations];
      const index = transitAuthorisations.findIndex((t) => t.type === row.type);
      transitAuthorisations.splice(index, 1);

      props.form.setFieldValue('transitAuthorisations', transitAuthorisations);
    }
  };

  const handleSavingAdditionalFiscalReference = (updatedTransitAuthReference: {
    type: string;
    referenceNumber: string;
  }) => {
    const transitAuthorisations = [...props.form.values.transitAuthorisations];
    const alreadyExists = transitAuthorisations.find((t) => t.type === updatedTransitAuthReference.type);
    const index = transitAuthorisations.findIndex((t) => t.type === updatedTransitAuthReference.type);

    if (!!alreadyExists && selectedIndex >= 0 && selectedIndex !== index) {
      toast.error('This authorisation type already exists for this customer!');
      return;
    }

    if (alreadyExists) {
      transitAuthorisations.splice(index, 1, updatedTransitAuthReference);
    } else {
      transitAuthorisations.push(updatedTransitAuthReference);
    }

    props.form.setFieldValue('transitAuthorisations', transitAuthorisations);
  };

  const typeValue = (t) => {
    return authorisationTypes.find((type) => {
      return type.value === t;
    });
  };

  const columns: IDataTableProps['columns'] = [
    {
      name: 'Description',
      cell: (row) => <div>{typeValue(row.type)?.shortDescription}</div>,
      sortable: false,
    },
    {
      name: 'Code',
      selector: 'type',
      sortable: false,
    },
    {
      name: 'Reference number',
      selector: 'referenceNumber',
      sortable: false,
    },
    {
      name: 'Actions',
      right: true,
      cell: (row: { type: string; referenceNumber: string }, rowIndex: number) => (
        <TableActionButtons
          actions={[TableActions.Edit, TableActions.Delete]}
          noSpaceBetweenButtons
          onClick={(actionType) => {
            handleTableAction(actionType, row, rowIndex);
          }}
        />
      ),
    },
  ];

  const handleSubmitCustomer = () => {
    props.handleSubmitCustomer(EditCustomerType.TRANSIT_AUTH);
  };

  return (
    <>
      <Style.Container>
        <Style.Title>Authorisations for transit procedure</Style.Title>
        <Style.AuthButton>
          <Button onClick={toggleShowReferenceModal} primary type="submit">
            Add
          </Button>
        </Style.AuthButton>
      </Style.Container>

      <DataTableContainer>
        <DataTable noHeader columns={columns} data={props.form.values.transitAuthorisations} pagination />
      </DataTableContainer>

      {showModal && (
        <TransitAuthFormModal
          transitAuthEdit={selectedReference}
          show={showModal}
          onHide={toggleShowReferenceModal}
          onSave={handleSavingAdditionalFiscalReference}
        />
      )}

      <Style.AuthButton>
        <Button onClick={handleSubmitCustomer} primary type="submit">
          {context === 'CREATE' ? 'Create' : 'Save'}
        </Button>
      </Style.AuthButton>
    </>
  );
};

export default CustomerDetailsTransitAuth;
