/* eslint-disable default-case */
/* eslint-disable complexity */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Tab, Tabs } from '../../components';
import { clearTransits, fetchTransit, selectTransit, updateTransit } from '../../stores/transitsSlice';
import TransitDetailsHeader from './header/transit-details-header.component';
import { TransitDetailsCustoms } from './tabs/transit-details-customs/transit-details-customs.component';
import { TransitGeneralInfo } from './tabs/transit-details-general-info.component';
import * as Style from './transit-details.style';
import { TransitPersons } from './tabs/transit-details-persons.component';
import { TransitDetailsHouse } from './tabs/transit-details-house';
import { useDispatch, useSelector } from 'react-redux';
import { Transit, TransitStatus } from '@e-origin/shared';
import { useFormik } from 'formik';
import { cloneDeep, mergeWith } from 'lodash';

const TransitDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [editDisabled, setEditDisabled] = useState<boolean>(true);
  const transit = useSelector(selectTransit);

  const form = useFormik<Transit>({
    initialValues: undefined,

    onSubmit: async (values) => {
      dispatch(
        updateTransit(
          mergeWith(cloneDeep(transit), values, (objValue, srcValue) =>
            Array.isArray(srcValue) ? srcValue : undefined,
          ),
        ),
      );
    },
  });

  useEffect(() => {
    if (transit) {
      // todo: don't spread the transit object
      form.setValues(cloneDeep(transit));
    }
  }, [transit]);

  useEffect(() => {
    dispatch(fetchTransit(id));

    return () => {
      dispatch(clearTransits());
    };
  }, []);

  useEffect(() => {
    if (transit) {
      const isRejected =
        transit.generalInfo?.status === TransitStatus.REJECTED ||
        transit.generalInfo?.status === TransitStatus.NOT_SENT;
      setEditDisabled(!isRejected);
    }
  }, [transit]);

  return (
    <Style.Container>
      {transit && <TransitDetailsHeader form={form} transitDetails={transit} editDisabled={editDisabled} />}
      {form.values && (
        <>
          <Tabs style={{ padding: '0 50px', marginTop: 20 }} contentStyle={{ padding: '0 50px', overflow: 'auto' }}>
            <Tab label="General Info">
              <TransitGeneralInfo form={form} editDisabled={editDisabled} />
            </Tab>
            <Tab label="Persons">
              <TransitPersons form={form} editDisabled={editDisabled} />
            </Tab>
            <Tab label="Batches"></Tab>
            <Tab label="House consignments">
              <TransitDetailsHouse form={form} editDisabled={editDisabled} />
            </Tab>
            <Tab label="Customs State">
              <TransitDetailsCustoms transit={transit} />
            </Tab>
          </Tabs>
          {!editDisabled && (
            <Style.Footer>
              <Button type="submit" onClick={async () => form.submitForm()} primary key="save-modal-btn">
                Save
              </Button>
            </Style.Footer>
          )}
        </>
      )}
    </Style.Container>
  );
};

export default TransitDetails;
