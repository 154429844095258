/* eslint-disable max-classes-per-file */
/* eslint-disable max-lines */
import { Pre, prop } from '@typegoose/typegoose';

import { DeclarationAdditionalProcedure, ReferenceValueType, RefWebValueMethod } from '../enums';
import { BaseModel } from './base.model';

export class CommodityCode {
  @prop({ required: true })
  hsCode: string;

  @prop()
  hsCodeCustomsSystem: string;

  @prop()
  combinedNomenclatureCode?: string;

  @prop()
  taricCode?: string;

  @prop()
  taricAdditionalCode?: string;

  @prop()
  nationalAdditionalCode?: string;
}

export class Commodity {
  @prop()
  material: string;

  @prop()
  descriptionOfGoodsOther: string;

  @prop()
  descriptionOfGoodsOtherTranslated: string;
}

export class GoodsMeasure {
  @prop()
  intrinsicValue: number;

  @prop({ default: 'EUR' })
  currencyCode: string;

  @prop()
  netMass: number;

  @prop()
  grossMass: number;

  @prop()
  supplementaryUnits: number;
}

export class TaxBase {
  @prop()
  taxRate?: number;

  @prop()
  measurementUnitAndQualifier?: string;

  @prop()
  quantity?: number;

  @prop()
  amount?: number;

  @prop()
  taxAmount?: number;
}

export class TransportInsurance {
  @prop()
  currencyCode: string;

  @prop()
  amount: number;
}

export class Packaging {
  @prop()
  numberOfPackages: number;

  @prop()
  typeOfPackages: string;

  @prop()
  shippingMarks: string;

  @prop()
  totalNumberOfPackages: number;

  @prop()
  totalQuantity: number;
}

export class DutiesAndTaxesItem {
  @prop()
  taxType?: string;

  @prop()
  methodOfPayment?: string;

  @prop()
  payableTaxAmount?: number;

  @prop({ _id: false, type: [TaxBase] })
  taxBase?: TaxBase[];
}

export class DutiesAndTaxes {
  @prop({ _id: false, type: [DutiesAndTaxesItem] })
  items: DutiesAndTaxesItem[];

  @prop()
  totalDutiesAndTaxesAmount: number;
}

export class AdditionsAndDeductions {
  @prop()
  code: string;

  @prop()
  amount: number;
}

export class WebScrapingFile {
  @prop()
  url: string;

  @prop()
  screenshotForUrl?: string;

  @prop()
  bucketFile: string;

  @prop()
  type: string;

  @prop()
  timestamp?: Date;
}

export class WebScrapingExchangeRate {
  @prop()
  currency: string;

  @prop()
  rate: number;

  @prop()
  date: string;
}

export class OtherFeeInfoMapItemTotal {
  @prop()
  amount: number;
}

export class OtherFeeInfoMapItem {
  @prop()
  timeOfFeesEstimate: number;

  @prop({ _id: false, type: OtherFeeInfoMapItemTotal })
  total: OtherFeeInfoMapItemTotal;
}

export class OtherFeeInfoMap {
  @prop()
  timeOfFeesEstimate: number;

  @prop({ _id: false, type: OtherFeeInfoMapItem })
  fulfillmentFee: OtherFeeInfoMapItem;

  @prop({ _id: false, type: OtherFeeInfoMapItem })
  fixedClosingFee: OtherFeeInfoMapItem;

  @prop({ _id: false, type: OtherFeeInfoMapItem })
  referralFee: OtherFeeInfoMapItem;

  @prop({ _id: false, type: OtherFeeInfoMapItem })
  variableClosingFee: OtherFeeInfoMapItem;
}

export class VatAmount {
  @prop()
  amount: number;

  @prop()
  currency: string;
}
export class OtherCost {
  @prop()
  vatRate: number;

  @prop({ _id: false, type: VatAmount })
  vatAmount: VatAmount;
}

export class PerUnitPeakStorageFee {
  @prop()
  timeOfFeesEstimate: number;

  @prop({ _id: false, type: VatAmount })
  total: VatAmount;
}

export class PerUnitNonPeakStorageFee {
  @prop()
  timeOfFeesEstimate: number;

  @prop({ _id: false, type: VatAmount })
  total: VatAmount;
}

export class ScrapingLogs {
  @prop()
  additionalProductDetails: string;

  @prop()
  productDetails: string;

  @prop()
  productFees: string;

  @prop()
  scrapedData: string;
}

export class WebScraping {
  @prop()
  countryCode: string;

  @prop()
  refWebValueMethod: RefWebValueMethod;

  @prop()
  sellerVat: string;

  @prop()
  sellerId: string;

  @prop()
  webUrl: string;

  @prop()
  webWeight: string;

  @prop()
  webDescriptionOfGoods: string;

  @prop({ default: 0 })
  webItemAmountResale: number;

  @prop({ type: [String], default: [] })
  webCategories: string[];

  @prop({ _id: false, type: [WebScrapingFile], default: [] })
  webAdditionalFiles: WebScrapingFile[];

  @prop({ _id: false, type: WebScrapingExchangeRate })
  exchangeRate: WebScrapingExchangeRate;

  @prop({ _id: false, type: OtherCost })
  otherCost: OtherCost;

  @prop({ _id: false, type: PerUnitPeakStorageFee })
  perUnitPeakStorageFee: PerUnitPeakStorageFee;

  @prop({ _id: false, type: PerUnitNonPeakStorageFee })
  perUnitNonPeakStorageFee: PerUnitNonPeakStorageFee;

  @prop({ _id: false, type: OtherFeeInfoMap })
  otherFeeInfoMap: OtherFeeInfoMap;

  @prop({ _id: false, type: ScrapingLogs })
  logs?: ScrapingLogs;
}

export class TaricSupplementaryUnit {
  @prop()
  codeQualifyer: string;

  @prop()
  abbreviation: string;
}

export class TaricOtherMeasures {
  @prop()
  originCode: string;

  @prop()
  measureType: string;

  @prop()
  duty: string;
}

export class EvalMethodDescription {
  @prop({ default: false })
  webMatchingDescription: boolean;

  @prop({ default: '' })
  suggestedDescriptionOfGoods: string;

  @prop({ default: false })
  validatedDescriptionCheck: boolean;
}

export class EvalMethodWebValue {
  @prop()
  vatSellerOk: boolean;

  @prop()
  customsCharges: number; // value in euro

  @prop()
  vatCharges: number; // value in euro

  @prop()
  vatRateMarketplace: number;

  @prop()
  webTotalAmountVAT: number;

  @prop()
  webItemAmountResale: number;

  @prop()
  webTotalAmountResale: number;

  @prop()
  webTotalAmountResaleExclVAT: number;

  @prop()
  marketplaceFees: number;

  @prop()
  fullfillmentCost: number;

  @prop()
  storageCosts: number;

  @prop()
  otherCosts: number;

  @prop()
  vatBase: number;

  @prop()
  paramMarketplaceFees: number;

  @prop()
  paramFullfillmentCost: number;

  @prop()
  paramStorageCosts: number;

  @prop()
  paramOtherCosts: number;

  @prop()
  paramMinimumRate: number;

  @prop()
  invoicedAmountPerKg: number;

  @prop()
  invoicedMassCoefficient: number;

  @prop()
  webAmountPerKg: number;

  @prop()
  invoicedRate: number;

  @prop()
  invoicedRateOk: boolean;

  @prop()
  netInvoicedProfit: number;

  @prop()
  netMargin: number;

  @prop()
  webValue?: number;

  @prop()
  webValueRate: number;

  @prop()
  webDuty?: number;

  @prop()
  webVat?: number;

  @prop()
  webTotalDutiesAndTaxes: number;

  @prop()
  webWeight: string;

  @prop()
  webWeightOk?: boolean;

  @prop({ default: false })
  validatedValueCheck: boolean;
}

export class StatisticalValue {
  @prop()
  productNc: string;

  @prop()
  valueInEuros: number;

  @prop()
  quantityInKg: number;

  @prop()
  value: number;

  @prop()
  madbHSCode: string;

  @prop()
  madbAmountPerKg: number;

  @prop()
  madbInvoicedRate: number;

  @prop()
  madbInvoicedRateOk: boolean;

  @prop()
  imadbValue: number;

  @prop()
  imadbDuty: number;

  @prop()
  imadbVat: number;

  @prop()
  totalDutiesAndTaxesAmount: number;
}

export class PredictedDistance extends Array {
  // hsCode
  0: number;

  // description
  1: string;

  // number of occurances
  2: number;

  // probability (ranged from 0-100)
  3: number;
}

export class EvalMethodStatisticalValue {
  @prop({ _id: false, type: StatisticalValue })
  lowest: StatisticalValue;

  @prop({ _id: false, type: StatisticalValue })
  highest: StatisticalValue;

  @prop({ _id: false, type: StatisticalValue })
  suggestedLowest: StatisticalValue;

  @prop({ _id: false, type: StatisticalValue })
  suggestedHighest: StatisticalValue;

  @prop({ default: false })
  validatedStatisticalValue: boolean;
}

export class EvalMethodAiHsCode {
  @prop()
  calculationDistance?: number;

  @prop()
  distanceInRange?: boolean;

  @prop()
  aiResultLog?: string;

  @prop({ default: '' })
  suggestedHsCode: string;

  @prop()
  suggestedTaricRate: string;

  @prop({ type: [[String]] })
  taricDescriptionList?: string[][];

  @prop({ type: [[String]] })
  taricDescriptionListException?: string[][];

  @prop({ type: [String] })
  taricDuty?: string[];

  @prop({ type: [String] })
  suggestedTaricDuty?: string[];

  @prop({ _id: false, type: [TaricSupplementaryUnit] })
  taricSupplementaryUnits?: TaricSupplementaryUnit[];

  @prop({ _id: false, type: [TaricOtherMeasures] })
  taricOtherMeasures?: TaricOtherMeasures[];

  @prop()
  taricDutyNotFound?: boolean;

  @prop()
  taricWithRestrictions?: boolean;

  @prop()
  taricRatioToCheck?: boolean;

  @prop()
  calculatedRatio?: number;

  @prop()
  taricOriginException?: boolean;

  @prop()
  suggestedDutyRate?: number;

  @prop()
  adjustedDuty?: number;

  @prop()
  adjustedVat?: number;

  @prop()
  adjustedTotalDutiesAndTaxesAmount?: number;

  @prop()
  additionalDutiesAndTaxesAmount?: number;

  @prop()
  givenSection?: number;

  @prop()
  givenDistance?: number;

  @prop()
  predictedDistances?: PredictedDistance[];

  @prop()
  errorDistance?: number;

  @prop({ default: false })
  hsCodeOk?: boolean;

  @prop()
  hsCodeFound?: boolean;

  @prop()
  annotationNote: string;

  @prop()
  annotationCustomsDecision: boolean;

  @prop({ default: false })
  validatedHSCodeCheck: boolean;
}

export class SuggestedValues {
  @prop({
    enum: [ReferenceValueType.INVOICE, ReferenceValueType.WEB, ReferenceValueType.IMADB, ReferenceValueType.IMADB2],
  })
  referenceValue?: string;

  @prop()
  dutyRate?: number;

  @prop()
  unitValue?: number;

  @prop()
  totalValue?: number;

  @prop()
  payableDuty?: number;

  @prop()
  payableVat?: number;

  @prop()
  totalDutiesAndTaxesAmount?: number;

  @prop()
  adjustedDuty?: number;

  @prop()
  adjustedVat?: number;

  @prop()
  adjustedTotalDutiesAndTaxesAmount?: number;

  @prop()
  additionalDuties?: number;

  @prop()
  additionalVat?: number;

  @prop()
  additionalDutiesAndTaxesAmount?: number;

  @prop()
  vatRate?: number;
}

export class InvoiceLine {
  @prop()
  goodsItemLineNumber: number;

  @prop()
  totalAmountInvoiced: number;

  @prop()
  quantity: number;

  @prop()
  numberOfPackages: number;

  @prop()
  netMass: number;

  @prop()
  grossMass: number;

  @prop()
  shippingMarks: string;

  @prop()
  trackingId: string;

  @prop()
  invoicedAmountPerKg: number;

  @prop()
  countryOfOrigin: string;
}

export class DeclarationGoods extends BaseModel {
  @prop({ required: true })
  goodsItemNumber: number;

  @prop({ required: false })
  goodsItemLineNumber: number;

  @prop({ _id: false, type: [InvoiceLine], required: false })
  invoiceLines?: InvoiceLine[];

  @prop()
  referenceNumberUCR: string;

  @prop({ default: false })
  anomaliesDetected: boolean;

  @prop({ type: [String] })
  requestedProcedure: string[];

  @prop({ type: [String] })
  previousProcedure: string[];

  @prop({ type: [String], required: true, enum: DeclarationAdditionalProcedure })
  additionalProcedure: string[];

  @prop({ required: true })
  descriptionOfGoods: string;

  @prop({ _id: false, type: CommodityCode })
  commodityCode: CommodityCode;

  @prop({ _id: false, type: Commodity })
  commodity: Commodity;

  @prop({ _id: false, type: GoodsMeasure })
  goodsMeasure: GoodsMeasure;

  @prop({ _id: false, type: TransportInsurance })
  transportInsurance: TransportInsurance;

  @prop({ _id: false, type: Packaging })
  packaging: Packaging;

  @prop({ _id: false, type: DutiesAndTaxes })
  dutiesAndTaxes: DutiesAndTaxes;

  @prop()
  internalCurrencyUnit: string;

  @prop({ _id: false, type: AdditionsAndDeductions })
  additionsAndDeductions: AdditionsAndDeductions;

  @prop()
  totalAmountInvoiced: number;

  @prop()
  valuationIndicators: string;

  @prop()
  itemAmountInvoiced: number;

  @prop()
  valuationMethod: number;

  @prop()
  preference: number;

  @prop()
  dateOfAcceptance: string;

  @prop({ _id: false, type: WebScraping, default: {} })
  webScraping: WebScraping;

  @prop({ _id: false, type: EvalMethodDescription, default: {} })
  evalMethodDescription: EvalMethodDescription;

  @prop({ _id: false, type: EvalMethodWebValue, default: {} })
  evalMethodWebValue: EvalMethodWebValue;

  @prop({ _id: false, type: EvalMethodAiHsCode, default: {} })
  evalMethodAiHsCode: EvalMethodAiHsCode;

  @prop({ _id: false, type: EvalMethodStatisticalValue })
  evalMethodStatisticalValue: EvalMethodStatisticalValue;

  @prop({ _id: false, type: SuggestedValues })
  suggestedValues?: SuggestedValues;

  @prop({ default: false })
  invoicedRateOk: boolean;

  @prop()
  invoiceCurrency: string;

  @prop()
  exchangeRate: number;
}
