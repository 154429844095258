import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-col
    flex-1
    h-full
    overflow-hidden
  `}
`;

export const Footer = styled.div`
  border-top: 1px solid rgba(222, 222, 222, 0.8);
  padding: 20px 40px;
`;
