import styled from 'styled-components';
import tw from 'twin.macro';

export const Header = styled.div`
  ${tw`
    flex
    items-center
    justify-between
    mb-8
  `}
`;

export const Title = styled.h2`
  ${tw`
    font-bold
    text-xl
  `}
`;

export const BackButton = styled.button`
  ${tw`
    flex
    items-center
    outline-none
    focus:outline-none
    font-bold
  `}
  font-size:18px;
  letter-spacing: 0;
  line-height: 21px;
  width: fit-content;

  img {
    margin-right: 5px;
  }
`;
