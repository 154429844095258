/* eslint-disable max-lines */
/* eslint-disable no-use-before-define */
/* eslint-disable complexity */
import React from 'react';

import { Transit, TransitStatus } from '@e-origin/shared';

import {
  BusinessRejectionRow,
  Container,
  ErrorSection,
  FunctionalErrorItem,
  FunctionalErrorRow,
  FunctionalErrorTitle,
  Header,
  RejectionInfo,
} from './transit-details-customs.style';

interface ITransitDetailsCustoms {
  transit: Transit;
}

export const TransitDetailsCustoms: React.FC<ITransitDetailsCustoms> = ({ transit }) => {
  if (!transit?.customsState) {
    return <div>Not sent yet</div>;
  }

  return (
    <Container>
      <Header>
        <div>
          <label>Status</label>
          <p>{transit.generalInfo.status}</p>
        </div>
        <div>
          <label>Correlation ID</label>
          <p>{transit.customsState?.correlationId || 'N/A'}</p>
        </div>
        {transit.customsState?.movementReference && (
          <div>
            <label>Movement reference number</label>
            <p>{transit.customsState?.movementReference}</p>
          </div>
        )}
        {transit.customsState?.dateOfAcceptance && (
          <div>
            <label>Date of acceptance</label>
            <p>{transit.customsState?.dateOfAcceptance}</p>
          </div>
        )}
        {transit.customsState?.noRelease?.rejectionDate && (
          <div>
            <label>Rejection date</label>
            <p>{transit.customsState?.noRelease?.rejectionDate}</p>
          </div>
        )}
      </Header>

      {transit.generalInfo.status === TransitStatus.REJECTED && (
        <RejectionInfo>
          {transit.customsState.customsDeclarationRejection && (
            <ErrorSection>
              <BusinessRejectionRow>
                <div>
                  <label>Business rejection type</label>
                  <p>{transit.customsState.customsDeclarationRejection.businessRejectionType}</p>
                </div>
              </BusinessRejectionRow>
              <BusinessRejectionRow>
                <div>
                  <label>Rejection date</label>
                  <p>{transit.customsState.customsDeclarationRejection.rejectionDate}</p>
                </div>
                {transit.customsState.customsDeclarationRejection.rejectionCode && (
                  <div>
                    <label>Rejection code</label>
                    <p>{transit.customsState.customsDeclarationRejection.rejectionCode}</p>
                  </div>
                )}
                {transit.customsState.customsDeclarationRejection.rejectionReason && (
                  <div>
                    <label>Rejection reason</label>
                    <p>{transit.customsState.customsDeclarationRejection.rejectionReason}</p>
                  </div>
                )}
              </BusinessRejectionRow>
            </ErrorSection>
          )}
          {transit.customsState.functionalError && (
            <ErrorSection>
              {transit.customsState?.functionalError &&
                transit.customsState?.functionalError.map((item, index) => (
                  <FunctionalErrorRow key={index}>
                    <FunctionalErrorTitle>Functional error</FunctionalErrorTitle>
                    <FunctionalErrorItem>
                      {Number(item.sequenceNumber) > 0 && (
                        <div>
                          <label>Sequence number</label>
                          <p>{item.sequenceNumber}</p>
                        </div>
                      )}
                      <div>
                        <label>Error code</label>
                        <p>{item.errorCode}</p>
                      </div>
                    </FunctionalErrorItem>
                    <FunctionalErrorItem>
                      <div>
                        <label>Error pointer</label>
                        <p>{item.errorPointer}</p>
                      </div>
                    </FunctionalErrorItem>
                    <FunctionalErrorItem>
                      <div>
                        <label>Error reason</label>
                        <p>{item.errorReason}</p>
                      </div>
                    </FunctionalErrorItem>
                    {item.remarks && item.remarks.length && (
                      <FunctionalErrorItem>
                        <div>
                          <label>Remarks</label>
                          <p>{item.remarks}</p>
                        </div>
                      </FunctionalErrorItem>
                    )}
                    {item.originalAttributeValue && item.originalAttributeValue.length && (
                      <FunctionalErrorItem>
                        <div>
                          <label>Original Attribute</label>
                          <p>{item.originalAttributeValue}</p>
                        </div>
                      </FunctionalErrorItem>
                    )}
                  </FunctionalErrorRow>
                ))}
            </ErrorSection>
          )}
        </RejectionInfo>
      )}
    </Container>
  );
};
