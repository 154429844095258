export const authorisationTypes = [
  {
    label: 'C520 - authorised consignee for TIR',
    value: 'C520',
    shortDescription: 'authorised consignee for TIR',
    longDescription:
      'ACT - Authorisation for the status of authorised consignee for TIR procedure (Column 9a, Annex A of Delegated Regulation (EU) 2015/2446)',
  },
  {
    label: 'C521 - authorised consignor for Union transit',
    value: 'C521',
    shortDescription: 'authorised consignor for Union transit',
    longDescription:
      'ACR - Authorisation for the status of authorised consignor for Union transit (Column 9b, Annex A of Delegated Regulation (EU) 2015/2446)',
  },
  {
    label: 'C522 - authorised consignee for Union transit',
    value: 'C522',
    shortDescription: 'authorised consignee for Union transit',
    longDescription:
      'ACE - Authorisation for the status of authorised consignee for Union transit (Column 9c, Annex A of Delegated Regulation (EU) 2015/2446)',
  },
  {
    label: 'C523 - seals of a special type',
    value: 'C523',
    shortDescription: 'seals of a special type',
    longDescription:
      'SSE - Authorisation for the use of seals of a special type (Column 9d, Annex A of Delegated Regulation (EU) 2015/2446)',
  },
  {
    label: 'C524 - transit declaration with a reduced dataset',
    value: 'C524',
    shortDescription: 'transit declaration with a reduced dataset',
    longDescription:
      'TRD - Authorisation to use transit declaration with a reduced dataset (Column 9e, Annex A of Delegated Regulation (EU) 2015/2446)',
  },
];
