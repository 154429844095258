/* eslint-disable complexity */
import { FormikProps } from 'formik';

import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../../../../../../styles/common';
import { GoodsItemFormInitializerReturn } from '../goods-item-form-initializer';
import { Input } from '../../../../../../../../components';
import TransitDuplicateRow from '../../../../../../duplicate-transit-row/duplicate-transit-row.component';
import { TransitHouseConsignment } from '@e-origin/shared';
import { retrieveCodes } from '../../../../../../../../stores/settingsSlice';

interface GoodsItemStakeholdersProps {
  form: FormikProps<GoodsItemFormInitializerReturn>;
  houseConsignment: TransitHouseConsignment;
  disabled: boolean;
}

export const GoodsItemStakeholders: React.FC<GoodsItemStakeholdersProps> = (props) => {
  const { form } = props;

  return (
    <>
      <FormContainer>
        <FormSection verticalPadding={0} noTopPadding>
          <FormSectionTitle noTopBorder>Consignee</FormSectionTitle>
          <FormRow>
            <Input
              name="ConsignmentItem.Consignee.identificationNumber"
              placeholder="Identification number"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignee.identificationNumber}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled || !!props.houseConsignment?.Consignee?.identificationNumber}
            />
            <Input
              name="ConsignmentItem.Consignee.name"
              placeholder="Name"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignee.name}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled || !!props.houseConsignment?.Consignee?.name}
            />
          </FormRow>
          <FormRow>
            <Input
              name="ConsignmentItem.Consignee.Address.streetAndNumber"
              placeholder="Street and Number"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignee.Address.streetAndNumber}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled || !!props.houseConsignment?.Consignee?.Address?.streetAndNumber}
            />
            <Input
              name="ConsignmentItem.Consignee.Address.postcode"
              placeholder="Postcode"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignee.Address.postcode}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled || !!props.houseConsignment?.Consignee?.Address?.postcode}
            />
            <Input
              name="ConsignmentItem.Consignee.Address.city"
              placeholder="City"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignee.Address.city}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled || !!props.houseConsignment?.Consignee?.Address?.city}
            />
            <Input
              name="ConsignmentItem.Consignee.Address.country"
              placeholder="Country"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Consignee.Address.country}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled || !!props.houseConsignment?.Consignee?.Address?.country}
            />
          </FormRow>
        </FormSection>
        <FormSection verticalPadding={0}>
          <FormSectionTitle>Additional supply chain actor</FormSectionTitle>
          <TransitDuplicateRow
            addButtonLabel="Add new additional supply chain actor"
            form={form}
            inputs={[
              {
                type: 'Autocomplete',
                label: 'Role',
                name: 'role',
                search: (searchText) => retrieveCodes('CL704', searchText),
              },
              {
                type: 'Input',
                label: 'Identification number',
                name: 'identificationNumber',
              },
            ]}
            arrayPath="AdditionalSupplyChainActor"
            compact
          />
        </FormSection>
      </FormContainer>
    </>
  );
};
