import { prop } from '@typegoose/typegoose';

import { Evaluation } from './evaluation';
import { RelatedResource } from './related-resource';

export class IdCard {
  @prop()
  ownerName?: string;

  @prop()
  expirationDate?: Date;

  @prop()
  title?: string;

  @prop()
  registrationNumber?: string;

  @prop()
  email?: string;

  @prop()
  phoneNumber?: string;

  @prop({ type: () => [RelatedResource] })
  relatedResources?: RelatedResource[];

  @prop()
  evalExpiration?: Evaluation;
}
