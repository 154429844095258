/* eslint-disable complexity */
import { ConsignmentItem, RecursivePartial, TransitHouseConsignment } from '@e-origin/shared';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GoodsItemFormInitializerReturn extends RecursivePartial<ConsignmentItem> {
  documents: {
    PreviousDocument: any[];
    SupportingDocument: any[];
    TransportDocument: any[];
    AdditionalReference: any[];
    AdditionalInformation: any[];
  };
}

export const goodsItemFormIntializer = (
  item: ConsignmentItem,
  house: TransitHouseConsignment,
): GoodsItemFormInitializerReturn => ({
  declarationType: item?.declarationType || '',
  countryOfDispatch: house?.countryOfDispatch || item?.countryOfDispatch || '',
  countryOfDestination: item?.countryOfDestination || '',
  referenceNumberUCR: house?.referenceNumberUCR || item?.referenceNumberUCR || '',
  Commodity: {
    descriptionOfGoods: item?.Commodity?.descriptionOfGoods || '',
    DangerousGoods: item?.Commodity?.DangerousGoods || [{ UNNumber: '' }],
    CommodityCode: {
      hsCode: item?.Commodity?.CommodityCode?.hsCode || '',
    },
    GoodsMeasure: {
      grossMass: item?.Commodity?.GoodsMeasure?.grossMass || '',
      netMass: item?.Commodity.GoodsMeasure?.netMass || '',
      supplementaryUnits: item?.Commodity?.GoodsMeasure?.supplementaryUnits || '',
    },
  },

  Packaging: item?.Packaging || [{ typeOfPackages: '', numberOfPackages: '', shippingMarks: '' }],
  Consignee: {
    identificationNumber: house?.Consignee?.identificationNumber || item?.Consignee?.identificationNumber || '',
    name: item?.Consignee?.name || '',
    Address: {
      streetAndNumber: house?.Consignee?.Address?.streetAndNumber || item?.Consignee?.Address?.streetAndNumber || '',
      postcode: house?.Consignee?.Address?.streetAndNumber || item?.Consignee?.Address?.postcode || '',
      city: house?.Consignee?.Address?.streetAndNumber || item?.Consignee?.Address?.city || '',
      country: house?.Consignee?.Address?.streetAndNumber || item?.Consignee?.Address?.country || '',
    },
  },
  AdditionalSupplyChainActor: house?.AdditionalSupplyChainActor?.length
    ? house.AdditionalSupplyChainActor
    : item?.AdditionalSupplyChainActor || [{ role: '', identificationNumber: '' }],
  TransportCharges: {
    methodOfPayment: item?.TransportCharges?.methodOfPayment,
  },
  documents: {
    PreviousDocument: house?.PreviousDocument?.length ? house.PreviousDocument : item?.PreviousDocument || [],
    SupportingDocument: house?.SupportingDocument?.length ? house.SupportingDocument : item?.SupportingDocument || [],
    TransportDocument: house?.TransportDocument?.length ? house.TransportDocument : item?.TransportDocument || [],
    AdditionalReference: house?.AdditionalReference.length
      ? house.AdditionalReference
      : item?.AdditionalReference || [],
    AdditionalInformation: house?.AdditionalInformation.length
      ? house.AdditionalInformation
      : item?.AdditionalInformation || [],
  },
});
