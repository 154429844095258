/* eslint-disable complexity */
import { RecursivePartial, TransitHouseConsignment } from '@e-origin/shared';

export interface HouseItemFormInitializerReturn extends RecursivePartial<TransitHouseConsignment> {
  documents: {
    PreviousDocument: any[];
    SupportingDocument: any[];
    TransportDocument: any[];
    AdditionalReference: any[];
    AdditionalInformation: any[];
  };
}

export const houseFormIntializer = (item: TransitHouseConsignment): HouseItemFormInitializerReturn => ({
  countryOfDispatch: item?.countryOfDispatch || '',
  grossMass: item?.grossMass || '',
  referenceNumberUCR: item?.referenceNumberUCR || '',
  Consignor: {
    identificationNumber: item?.Consignor?.identificationNumber || '',
    name: item?.Consignor?.name || '',
    Address: {
      streetAndNumber: item?.Consignor?.Address?.streetAndNumber || '',
      postcode: item?.Consignor?.Address?.postcode || '',
      city: item?.Consignor?.Address?.city || '',
      country: item?.Consignor?.Address?.country || '',
    },
  },
  Consignee: {
    identificationNumber: item?.Consignee?.identificationNumber || '',
    name: item?.Consignee?.name || '',
    Address: {
      streetAndNumber: item?.Consignee?.Address?.streetAndNumber || '',
      postcode: item?.Consignee?.Address?.postcode || '',
      city: item?.Consignee?.Address?.city || '',
      country: item?.Consignee?.Address?.country || '',
    },
  },
  AdditionalSupplyChainActor: item?.AdditionalSupplyChainActor.length
    ? item.AdditionalSupplyChainActor
    : [{ role: '', identificationNumber: '' }],
  DepartureTransportMeans: item?.DepartureTransportMeans.length
    ? item.DepartureTransportMeans
    : [{ typeOfIdentification: '', identificationNumber: '', nationality: '' }],
  documents: {
    PreviousDocument: item?.PreviousDocument || [],
    SupportingDocument: item?.SupportingDocument || [],
    TransportDocument: item?.TransportDocument || [],
    AdditionalReference: item?.AdditionalReference || [],
    AdditionalInformation: item?.AdditionalInformation || [],
  },
});
