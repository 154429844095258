/* eslint-disable complexity */
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../../../../../../styles/common';
import { GoodsItemFormInitializerReturn } from '../goods-item-form-initializer';
import { Autocomplete } from '../../../../../../../../components';
import { retrieveCodes } from '../../../../../../../../stores/settingsSlice';
import { selectDeclarant } from '../../../../../../../../stores/declarantSlice';

interface GoodsItemTransportProps {
  form: FormikProps<GoodsItemFormInitializerReturn>;
  disabled: boolean;
}

export const GoodsItemTransport: React.FC<GoodsItemTransportProps> = (props) => {
  const { form } = props;

  const declarant = useSelector(selectDeclarant);

  return (
    <>
      <FormContainer>
        <FormSection verticalPadding={0} noTopPadding>
          <FormSectionTitle noTopBorder>Transport charges</FormSectionTitle>
          <FormRow>
            <Autocomplete
              width={25}
              widthUnit="%"
              placeholder="Method of payment"
              fetchOptions={(search: string) => retrieveCodes('CL116', search, declarant.language)}
              onChange={(selectedOption) =>
                form.setFieldValue('TransportCharges.methodOfPayment', selectedOption?.value)
              }
              value={{
                value: form.values.TransportCharges.methodOfPayment,
                label: form.values.TransportCharges.methodOfPayment,
              }}
              disabled={props.disabled}
              compact
            />
          </FormRow>
        </FormSection>
      </FormContainer>
    </>
  );
};
