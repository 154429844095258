/* eslint-disable complexity */
import { FormikProps } from 'formik';

import { FormContainer, FormSection, FormSectionTitle } from '../../../../../../../../styles/common';
import { HouseItemFormInitializerReturn } from '../house-form-initializer';
import TransitDuplicateRow from '../../../../../../duplicate-transit-row/duplicate-transit-row.component';

interface HouseTransportProps {
  form: FormikProps<HouseItemFormInitializerReturn>;
  disabled: boolean;
}

export const HouseTransport: React.FC<HouseTransportProps> = (props) => {
  const { form } = props;

  return (
    <>
      <FormContainer>
        <FormSection verticalPadding={0} noTopPadding>
          <FormSectionTitle noTopBorder>Departure transport means</FormSectionTitle>
          <TransitDuplicateRow
            form={form}
            addButtonLabel="Add Departure Transport Means"
            inputs={[
              {
                type: 'Input',
                name: 'typeOfIdentification',
                label: 'Type of identification',
              },
              {
                type: 'Input',
                name: 'identificationNumber',
                label: 'Identification number',
              },
              {
                type: 'Input',
                name: 'nationality',
                label: 'Nationality',
              },
            ]}
            compact
            arrayPath="DepartureTransportMeans"
          />
        </FormSection>
      </FormContainer>
    </>
  );
};
