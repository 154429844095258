import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { Transit } from '@e-origin/shared';

import { retrieveCodes } from '../../../../src/stores/settingsSlice';
import { Autocomplete, Input } from '../../../components';
import { selectDeclarant } from '../../../stores/declarantSlice';
/* eslint-disable max-lines */
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../styles/common';
import TransitDetailsTransportEquipment from '../transit-details-transport-equipment/transit-details-transport-equipment.component';
import TransitDuplicateRow from '../duplicate-transit-row/duplicate-transit-row.component';

interface TransitGeneralInfoProps {
  form: FormikProps<Transit>;
  editDisabled: boolean;
}

export const TransitGeneralInfo: React.FC<TransitGeneralInfoProps> = ({ form, editDisabled }) => {
  const declarant = useSelector(selectDeclarant);

  return (
    <FormContainer>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>General</FormSectionTitle>
        <FormRow>
          <Input
            name="Consignment.referenceNumberUCR"
            placeholder="Reference number/UCR"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment.referenceNumberUCR}
            width={93}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Autocomplete
            placeholder="Declaration type"
            fetchOptions={(search: string) => retrieveCodes('CL231', search, declarant.language)}
            onChange={(selectedOption) => form.setFieldValue('TransitOperation.declarationType', selectedOption?.value)}
            value={{
              value: form.values.TransitOperation.declarationType,
              label: form.values.TransitOperation.declarationType,
            }}
            disabled={editDisabled}
          />
          <Autocomplete
            placeholder="Additional declaration"
            fetchOptions={(search: string) => retrieveCodes('CL042', search, declarant.language)}
            onChange={(selectedOption) =>
              form.setFieldValue('TransitOperation.additionalDeclarationType', selectedOption?.value)
            }
            value={{
              value: form.values.TransitOperation.additionalDeclarationType,
              label: form.values.TransitOperation.additionalDeclarationType,
            }}
            disabled={editDisabled}
          />
          <Autocomplete
            placeholder="Security"
            fetchOptions={(search: string) => retrieveCodes('CL217', search, declarant.language)}
            onChange={(selectedOption) => form.setFieldValue('TransitOperation.security', selectedOption?.value)}
            value={{
              value: form.values.TransitOperation.security,
              label: form.values.TransitOperation.security,
            }}
            disabled={editDisabled}
          />
        </FormRow>
        <FormRow>
          <Autocomplete
            placeholder="Reduced dataset"
            fetchOptions={(search: string) => retrieveCodes('CL027', search, declarant.language)}
            onChange={(selectedOption) =>
              form.setFieldValue('TransitOperation.reducedDatasetIndicator', selectedOption?.value)
            }
            value={{
              value: form.values.TransitOperation?.reducedDatasetIndicator,
              label: form.values.TransitOperation?.reducedDatasetIndicator,
            }}
            disabled={editDisabled}
          />
          <Autocomplete
            placeholder="Simplified procedure"
            fetchOptions={(search: string) => retrieveCodes('CL027', search, declarant.language)}
            onChange={(selectedOption) =>
              form.setFieldValue('TransitOperation.simplifiedProcedure', selectedOption?.value)
            }
            value={{
              value: form.values.TransitOperation?.simplifiedProcedure,
              label: form.values.TransitOperation?.simplifiedProcedure,
            }}
            disabled={editDisabled}
          />
          <Autocomplete
            placeholder="Specific circumstance indicator"
            fetchOptions={(search: string) => retrieveCodes('CL296', search, declarant.language)}
            onChange={(selectedOption) =>
              form.setFieldValue('TransitOperation.specificCircumstanceIndicator', selectedOption?.value)
            }
            value={{
              value: form.values.TransitOperation?.specificCircumstanceIndicator,
              label: form.values.TransitOperation?.specificCircumstanceIndicator,
            }}
            disabled={editDisabled}
          />
          <Autocomplete
            placeholder="Binding itinerary"
            onChange={form.handleChange}
            options={[
              { value: 'true', label: 'True' },
              { value: 'false', label: 'False' },
            ]}
            value={{
              value: form.values.TransitOperation?.bindingItinerary,
              label: form.values.TransitOperation?.bindingItinerary,
            }}
            disabled={editDisabled}
          />
        </FormRow>
        <FormRow>
          <Input
            name="CustomsOfficeOfDeparture.referenceNumber"
            placeholder="Customs office of departure"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.CustomsOfficeOfDeparture?.referenceNumber}
            width={93}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="CustomsOfficeOfDestinationDeclared.referenceNumber"
            placeholder="Customs office of destination"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.CustomsOfficeOfDestinationDeclared?.referenceNumber}
            width={93}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Autocomplete
            fetchOptions={(search: string) => retrieveCodes('CL008', search, declarant.language)}
            onChange={(selectedOption) => form.setFieldValue('Consignment.countryOfDestination', selectedOption?.value)}
            value={{
              value: form.values.Consignment?.countryOfDestination,
              label: form.values.Consignment?.countryOfDestination,
            }}
            placeholder="Country of destination"
            disabled={editDisabled}
          />
          <Input
            name=" Consignment.grossMass"
            placeholder="Gross mass"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.grossMass}
            width={93}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Customs decision</FormSectionTitle>
        <TransitDuplicateRow
          addButtonLabel="Add new authorisation"
          form={form}
          inputs={[
            {
              type: 'Autocomplete',
              label: 'Authorisation Type',
              name: 'type',
              width: 24,
              search: (searchText) => retrieveCodes('CL235', searchText),
            },
            {
              type: 'Input',
              name: 'referenceNumber',
              label: 'Authorization referenceNumber',
              width: 24,
            },
          ]}
          arrayPath="Authorisation"
        />
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Location of Goods</FormSectionTitle>
        <FormRow>
          <Autocomplete
            placeholder="Type of location"
            fetchOptions={(search: string) => retrieveCodes('CL347', search, declarant.language)}
            onChange={(selectedOption) =>
              form.setFieldValue('Consignment.LocationOfGoods.typeOfLocation', selectedOption?.value)
            }
            value={{
              value: form.values.Consignment?.LocationOfGoods?.typeOfLocation,
              label: form.values.Consignment?.LocationOfGoods?.typeOfLocation,
            }}
            disabled={editDisabled}
          />
          <Autocomplete
            placeholder="Qualifier of identification"
            fetchOptions={(search: string) => retrieveCodes('CL326', search, declarant.language)}
            onChange={(selectedOption) =>
              form.setFieldValue('Consignment.LocationOfGoods.qualifierOfIdentification', selectedOption?.value)
            }
            value={{
              value: form.values.Consignment?.LocationOfGoods?.qualifierOfIdentification,
              label: form.values.Consignment?.LocationOfGoods?.qualifierOfIdentification,
            }}
            disabled={editDisabled}
          />
          <Input
            name="Consignment.LocationOfGoods.additionalIdentifier"
            placeholder="Additional Identifier"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.LocationOfGoods?.additionalIdentifier}
            width={93}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Autocomplete
            placeholder="UNLocode"
            fetchOptions={(search: string) => retrieveCodes('CL244', search, declarant.language)}
            onChange={(selectedOption) =>
              form.setFieldValue('Consignment.LocationOfGoods.UNLocode', selectedOption?.value)
            }
            value={{
              value: form.values.Consignment?.LocationOfGoods?.UNLocode,
              label: form.values.Consignment?.LocationOfGoods?.UNLocode,
            }}
            disabled={editDisabled}
          />
        </FormRow>
        <FormRow>
          <Input
            name="Consignment.LocationOfGoods.Address.streetAndNumber"
            placeholder="Street and Number"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.LocationOfGoods?.Address?.streetAndNumber}
            width={50}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.LocationOfGoods.Address.postcode"
            placeholder="Postcode"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.LocationOfGoods?.Address?.postcode}
            width={50}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.LocationOfGoods.Address.city"
            placeholder="City"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.LocationOfGoods?.Address?.city}
            width={50}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.LocationOfGoods.Address.country"
            placeholder="Country"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.LocationOfGoods?.Address?.country}
            width={50}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Container</FormSectionTitle>
        <FormRow>
          <TransitDetailsTransportEquipment
            form={form}
            inputs={[
              {
                type: 'Input',
                label: 'Container',
                name: () => `Consignment.TransportEquipment.containerIdentificationNumber`,
                childs: [
                  {
                    type: 'Input',
                    name: () => `Consignment.TransportEquipment.Seal.identifier`,
                    label: 'Seal',
                  },
                ],
              },
            ]}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Transport</FormSectionTitle>
        <FormRow>
          <Autocomplete
            placeholder="Inland mode of transport"
            fetchOptions={(search: string) => retrieveCodes('CL218', search, declarant.language)}
            onChange={(selectedOption) =>
              form.setFieldValue('Consignment.inlandModeOfTransport', selectedOption?.value)
            }
            value={{
              value: form.values.Consignment?.inlandModeOfTransport,
              label: form.values.Consignment?.inlandModeOfTransport,
            }}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Autocomplete
            placeholder="Mode of transport at the border"
            fetchOptions={(search: string) => retrieveCodes('CL218', search, declarant.language)}
            onChange={(selectedOption) =>
              form.setFieldValue('Consignment.modeOfTransportAtTheBorder', selectedOption?.value)
            }
            value={{
              value: form.values.Consignment?.modeOfTransportAtTheBorder,
              label: form.values.Consignment?.modeOfTransportAtTheBorder,
            }}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Departure transport means</FormSectionTitle>
        <TransitDuplicateRow
          addButtonLabel="Add Transport Means"
          form={form}
          inputs={[
            {
              type: 'Autocomplete',
              label: 'Transport ID Type',
              name: 'typeOfIdentification',
              search: (searchText) => retrieveCodes('CL750', searchText),
              width: 24,
            },
            {
              type: 'Input',
              name: 'identificationNumber',
              label: 'Identification number',
              width: 24,
            },
            {
              type: 'Autocomplete',
              label: 'Nationality',
              name: 'nationality',
              width: 24,
              search: (searchText) => retrieveCodes('CL165', searchText),
            },
          ]}
          arrayPath="Consignment.DepartureTransportMeans"
        />
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Active transport means</FormSectionTitle>
        <TransitDuplicateRow
          addButtonLabel="Add Active Transport"
          form={form}
          inputs={[
            {
              type: 'Input',
              name: 'customsOfficeAtBorderReferenceNumber',
              label: 'Customs office at the border',
              width: 24,
            },
            {
              type: 'Autocomplete',
              label: 'Transport ID Type',
              name: 'typeOfIdentification',
              width: 24,
              search: (searchText) => retrieveCodes('CL219', searchText),
            },
            {
              type: 'Input',
              name: 'identificationNumber',
              label: 'Identification number',
              width: 24,
            },
            {
              type: 'Autocomplete',
              label: 'Nationality',
              name: 'nationality',
              width: 15,
              search: (searchText) => retrieveCodes('CL165', searchText),
            },
            {
              type: 'Input',
              name: 'conveyanceReferenceNumber',
              label: 'Conveyance reference number',
              width: 33,
            },
          ]}
          arrayPath="Consignment.ActiveBorderTransportMeans"
        />
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Place of loading</FormSectionTitle>
        <FormRow>
          <Input
            name="Consignment.PlaceOfLoading.UNLocode"
            placeholder="UN/LOCODC"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.PlaceOfLoading?.UNLocode}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.PlaceOfLoading.country"
            placeholder="Country"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.PlaceOfLoading?.country}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.PlaceOfLoading.location"
            placeholder="Location"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.PlaceOfLoading?.location}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Place of Unloading</FormSectionTitle>
        <FormRow>
          <Input
            name="Consignment.PlaceOfUnloading.UNLocode"
            placeholder="UN/LOCODC"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.PlaceOfUnloading?.UNLocode}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.PlaceOfUnloading.country"
            placeholder="Country"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.PlaceOfUnloading?.country}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.PlaceOfUnloading.location"
            placeholder="Location"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.PlaceOfUnloading?.location}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
      </FormSection>

      {/* todo - addapt to work with array as per domain model */}
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Customs office of transit</FormSectionTitle>
        <FormRow>
          {form.values.CustomsOfficeOfTransitDeclared?.map((customsOffice, index) => (
            <div key={index}>
              <Input
                name={`CustomsOfficeOfTransitDeclared[${index}].referenceNumber`}
                placeholder="Reference number"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={customsOffice?.referenceNumber}
                width={24}
                widthUnit="%"
                disabled={editDisabled}
              />
              <Input
                name={`CustomsOfficeOfTransitDeclared[${index}].arrivalDateAndTimeEstimated`}
                placeholder="Deadline"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={customsOffice?.arrivalDateAndTimeEstimated}
                width={24}
                widthUnit="%"
                disabled={editDisabled}
              />
            </div>
          ))}
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Transport charges</FormSectionTitle>
        <FormRow>
          <Autocomplete
            fetchOptions={(search: string) => retrieveCodes('CL116', search, declarant.language)}
            onChange={(selectedOption) =>
              form.setFieldValue('Consignment.TransportCharges.methodOfPayment', selectedOption?.value)
            }
            value={{
              value: form.values.Consignment?.TransportCharges?.methodOfPayment,
              label: form.values.Consignment?.TransportCharges?.methodOfPayment,
            }}
            placeholder="Method of payment"
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
      </FormSection>
    </FormContainer>
  );
};
