/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import { TransitDocumentsEnum } from '@e-origin/shared';
import { useEffect, useRef, useState } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { Button, CustomTableActionsComponent, NotificationModal } from '../../../components';
import { TableActionsIcons } from '../../../assets';

import * as Style from './transit-documents.style';
import { documentsLabels, TransitDocumentModal } from './transit-document-modal';

interface TransitDocumentsComponentProps {
  disabled: boolean;
  documents: any;
  onSave: (savedDocument: any) => void;
  onDelete: (documentType: any, documentIndex: number) => void;
}

export interface DocumentItemProps {
  type: TransitDocumentsEnum;
  label?: string;
  code: string;
  referenceNumber?: string;
  dateOfValidity?: string;
  docDetails: {
    type: TransitDocumentsEnum;
    dataSource: any;
    dataSourceIndex: number;
  };
}

export const TransitDocumentsComponent: React.FC<TransitDocumentsComponentProps> = (props) => {
  const [data, setData] = useState<DocumentItemProps[]>([]);
  const selectedRow = useRef<DocumentItemProps>(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleShowDocumentModal = () => {
    setShowModal(!showModal);

    if (showModal) {
      selectedRow.current = null;
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    selectedRow.current = null;
  };

  const handleDocumentDelete = () => {
    if (selectedRow.current) {
      props.onDelete(selectedRow.current.type, selectedRow.current.docDetails?.dataSourceIndex);
    }
    setShowDeleteModal(false);
  };

  const handleSavingDocument = (savedDocument: any) => {
    // if (!selectedRow.current) {
    props.onSave(savedDocument);
    // }
  };

  useEffect(() => {
    const tableData: DocumentItemProps[] = [];
    for (const [documentType, docs] of Object.entries(props.documents as any)) {
      if (Array.isArray(docs)) {
        docs.forEach((item, itemIdx) =>
          tableData.push({
            type: documentType as TransitDocumentsEnum,
            label: documentsLabels[documentType],
            code: item.type || item.code || item.role || '',
            referenceNumber:
              item.referenceNumber ||
              item.text ||
              item.vatIdentificationNumber ||
              item.reference?.customsOfficeGuarantee?.referenceNumber ||
              '',
            dateOfValidity: item.dateOfValidity,
            docDetails: { type: documentType as TransitDocumentsEnum, dataSource: item, dataSourceIndex: itemIdx },
          }),
        );
      }
    }

    setData(tableData);
  }, [props.documents]);

  const columns: IDataTableColumn[] = [
    {
      name: 'Type of document',
      selector: 'label',
      sortable: false,
    },
    {
      name: 'Code',
      selector: 'code',
      sortable: false,
    },
    {
      name: 'Reference',
      selector: 'referenceNumber',
      sortable: false,
      width: '250px',
    },
    {
      name: 'Date of validity',
      selector: 'dateOfValidity',
      sortable: false,
      width: '100px',
    },
    {
      name: 'Actions',
      right: true,
      cell: (row: DocumentItemProps) => (
        <CustomTableActionsComponent
          actions={[
            {
              icon: TableActionsIcons.VIEW,
              text: 'View details',
              onClick: () => {
                setShowModal(true);
                // pass
              },
            },
            {
              icon: TableActionsIcons.DELETE,
              text: 'Delete',
              onClick: () => {
                setShowDeleteModal(true);
              },
            },
          ]}
          noSpaceBetweenButtons
          onClick={() => {
            selectedRow.current = row;
          }}
        />
      ),
    },
  ];

  return (
    <Style.DocumentsContainer>
      <Style.DocumentsActionButton>
        {!props.disabled && (
          <Button outline onClick={toggleShowDocumentModal}>
            Add new
          </Button>
        )}
      </Style.DocumentsActionButton>
      <DataTable noHeader columns={columns} data={data || []} pagination />
      {showModal && (
        <TransitDocumentModal
          onHide={toggleShowDocumentModal}
          docDetails={selectedRow.current?.docDetails}
          onSave={handleSavingDocument}
          editDisabled={props.disabled}
        />
      )}
      <NotificationModal
        title="Confirm document delete"
        confirmButtonText="Delete"
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onConfirm={handleDocumentDelete}
        isDelete
      >
        <div>Are you sure you want to delete this document?</div>
      </NotificationModal>
    </Style.DocumentsContainer>
  );
};
