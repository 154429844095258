/* eslint-disable complexity */

import * as Style from './house-modal.style';
import { CustomModal } from '../../../../../../components';
import ModalStyle from '../../../../../../styles/modal';
import Icons from '../../../../../../assets';

import { HouseForm } from './house-form/house-form';
import { TransitHouseConsignment } from '@e-origin/shared';

export interface HouseModalProps {
  houseConsignment?: TransitHouseConsignment;
  editDisabled: boolean;
  onHide: () => void;
  onSave: (houseConsignment: TransitHouseConsignment) => void;
}

export const HouseModal: React.FC<HouseModalProps> = ({ houseConsignment, editDisabled, onHide, onSave }) => {
  const context = houseConsignment ? 'UPDATE' : 'CREATE';
  return (
    <CustomModal width={55} show={true} onHide={onHide}>
      <ModalStyle.Header>
        <Style.TitleContainer>
          <ModalStyle.Title>{context === 'CREATE' ? 'Create' : 'Update'} House Consignment</ModalStyle.Title>
        </Style.TitleContainer>

        <ModalStyle.HeaderActions>
          <button onClick={onHide}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      <HouseForm houseConsignment={houseConsignment} onSave={onSave} onHide={onHide} disabled={editDisabled} />
    </CustomModal>
  );
};
